<template>
  <div class="medical-report-page">
    <portal to="page-name">Laudo</portal>
    <template v-if="!loading">
      <report-header
        :data="form"
        :can-edit="canEdit"
        :is-external="isExternal"
        @change-template="changeTemplate"
        ref="header"
      ></report-header>

      <div class="medical-report-content">
        <report-content
          :data="form"
          :can-edit="canEdit"
          :is-external="isExternal"
          @save="save(false, $event)"
          ref="content"
        ></report-content>
        <report-sidebar
          :data="form"
          :can-edit="canEdit"
          :can-re-sign="canReSign"
          :is-external="isExternal"
          :is-finished="isFinished"
          @save="save(false)"
          @sign="sign"
          @resign="resign"
          @change-mask="changeMask"
          @external-report="setExternalReport"
        ></report-sidebar>
      </div>
    </template>

    <dx-modal title="Assinar laudo" size="sm"
              v-model="signModal.show" id="modal-finish">
      <div class="columns">
        <div class="column col-12 form-group"
             :class="{'has-error': $v.signModal.form.professionalId.$error}">
          <label for="professional" class="form-label">Profissional</label>
          <select id="professional" name="professional"
                  class="form-select"
                  @blur="$v.signModal.form.professionalId.$touch()"
                  v-model="signModal.form.professionalId">
            <option value="">Selecione um profissional</option>
            <option v-for="(item, i) in signModal.professionals"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
          <template v-if="$v.signModal.form.professionalId.$error">
            <div class="form-input-hint"
                 v-if="!$v.signModal.form.professionalId.required">
              Campo obrigatório
            </div>
          </template>
        </div>
        <div class="column col-12">
          <label class="form-switch">
            <input type="checkbox" v-model="signModal.reminder"/>
            <i class="form-icon"/> Lembre-me deste exame
          </label>
        </div>
        <div class="column col-12 form-group" v-if="signModal.reminder"
             :class="{'has-error': $v.signModal.form.reminderDate.$error}">
          <label class="form-label">Data</label>
          <dx-input-date
            id="reminder-date" class="form-input text-center"
            v-model="signModal.form.reminderDate" placeholder="00/00/0000"
            @blur="$v.signModal.form.reminderDate.$touch()"
          />
          <template v-if="$v.signModal.form.reminderDate.$error">
            <div class="form-input-hint"
                 v-if="!$v.signModal.form.reminderDate.required">Campo obrigatório</div>
            <div class="form-input-hint"
                 v-else-if="!$v.signModal.form.reminderDate.date">Data inválida</div>
            <div class="form-input-hint"
                 v-else-if="!$v.signModal.form.reminderDate.minDate">
              Data inválida
            </div>
          </template>
        </div>
        <div class="column col-12"
             v-if="form.stage === 'waiting'
             || form.stage === 'examined'
             || form.stage === 'report'">
          <label class="form-switch">
            <input type="checkbox" v-model="signModal.review">
            <i class="form-icon"></i>Enviar para revisão
          </label>
        </div>
        <div class="column col-12 form-group" v-if="signModal.review"
             :class="{'has-error': $v.signModal.form.professionalReviewId.$error}">
          <label for="professional-review" class="form-label">Profissional</label>
          <select id="professional-review" name="professional-review"
                  class="form-select"
                  @blur="$v.signModal.form.professionalReviewId.$touch()"
                  v-model="signModal.form.professionalReviewId">
            <option value="">Selecione um profissional</option>
            <option v-for="(item, i) in signModal.professionals"
                    :value="item.id" :key="i">{{ item.name }}</option>
          </select>
          <template v-if="$v.signModal.form.professionalReviewId.$error">
            <div class="form-input-hint"
                 v-if="!$v.signModal.form.professionalReviewId.required">
              Campo obrigatório
            </div>
          </template>
        </div>
      </div>
      <template v-slot:footer>
        <button class="btn btn-primary mr-1"
                :class="{loading: saving}"
                :disabled="saving"
                @click="save(true)">
          <fa-icon class="icon" :icon="['fal', 'user-edit']"></fa-icon>
          Assinar
        </button>
        <button class="btn" @click="signModal.show = false">Sair</button>
      </template>
    </dx-modal>

    <div class="bottom-bar" v-show="hasPortal">
      <portal-target
        name="report-bottom-bar"
        @change="hasPortal=$event"
      ></portal-target>
    </div>

    <patient-alert
      v-if="patientAlertModal.show"
      :patient-id="form.appointment.patient.id"
      :patient-name="form.appointment.patient.name"
      :external-data="patientAlertModal.data"
      :show="patientAlertModal.show"
      @close="patientAlertModal.show = false"
    />
  </div>
</template>

<script>
import formMixin from 'src/mixins/form';
import { mapState } from 'vuex';
import { mergeFrom } from '@/helpers/object';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '@/data/validators';
import * as professionalApi from '@/app/professional/api';
import { REASSIGN_MEDICAL_REPORT } from '@/data/actions/modules/clinical';
import ReportHeader from './report/Header.vue';
import ReportContent from './report/Content.vue';
import ReportSidebar from './report/Sidebar.vue';
import { normalizeImages } from './helpers';
import PatientAlert from '../../patient/modals/Alert.vue';

export default {
  mixins: [formMixin],
  components: {
    ReportHeader,
    ReportContent,
    ReportSidebar,
    PatientAlert,
  },
  data() {
    return {
      templates: [],
      form: this.blankForm(),
      loading: true,
      saving: false,
      signModal: {
        show: false,
        professionals: [],
        review: false,
        reminder: false,
        form: this.blankSignModalForm(),
      },
      hasPortal: false,
      patientAlertModal: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.load(this.$route.params.id);
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    isFinished() {
      return this.form.stage === 'finished';
    },
    canEdit() {
      if (this.isFinished) {
        return false;
      }
      if (['review', 'report'].includes(this.form.stage)) {
        const step = this.form.steps.find(({ status }) => status === this.form.stage);
        return step.professional.id === this.user.id;
      }

      return true;
    },
    canReSign() {
      return this.isFinished && this.canReassignAccess;
    },
    isExternal() {
      return !!this.form.externalReport;
    },
    canReassignAccess() {
      return this.$can(REASSIGN_MEDICAL_REPORT);
    },
  },
  validations() {
    const rules = {
      signModal: {
        form: {
          professionalId: { required },
          professionalReviewId: {},
          reminderDate: {},
        },
      },
    };

    if (this.signModal.reminder) {
      rules.signModal.form.reminderDate = { required, date, minDate: minDate() };
    }

    if (this.signModal.review) {
      rules.signModal.form.professionalReviewId = { required };
    }

    return rules;
  },
  methods: {
    load(id) {
      this.loading = true;
      return this.$http.get(`/medical-reports/${id}`)
        .then(({ data }) => {
          data.images = normalizeImages(data.images);
          this.form = mergeFrom(this.blankForm(), data);
          this.loadAlert();
        })
        .catch(() => {})
        .then(() => {
          this.loading = false;
        });
    },
    loadProfessionals() {
      return professionalApi.allActive()
        .then((data) => {
          this.signModal.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    loadAlert() {
      const params = {
        displayTo: 'medical_report',
      };

      this.$http.get(`/patients/${this.form.appointment.patient.id}/alerts`, { params })
        .then(({ data: result }) => {
          if (result && result.items && result.items.length > 0) {
            this.patientAlertModal.data = result;
            this.patientAlertModal.show = true;
          }
        })
        .catch(() => {});
    },
    changeTemplate(template) {
      this.form.templateId = template.id;
      this.form.inputs = template.inputs;
      this.form.measures = template.measures;
    },
    changeMask(mask) {
      if (
        mask
        && this.$refs.content
        && Array.isArray(this.$refs.content.inputs)
        && this.$refs.content.inputs.length === 1
      ) {
        this.$refs.content.inputs[0].model.value = mask.data;
      }
    },
    setExternalReport(data) {
      this.form.externalReport = data && data.externalReport
        ? data.externalReport
        : null;
    },
    save(sign = false) {
      if (!this.canEdit) {
        return;
      }

      if (sign) {
        this.$v.signModal.form.$touch();
        if (this.$v.signModal.form.$error) {
          return;
        }
      }

      this.saving = true;

      let stage = 'report';

      if (sign && !this.signModal.review) {
        stage = 'finished';
      } else if (sign && this.signModal.review) {
        stage = 'review';
      }

      const {
        inputs = [],
        measures = [],
        templateId,
      } = this.$refs.content;

      const reportData = {
        stage,
        professionalId: sign ? this.signModal.form.professionalId : this.user.id,
        patientId: this.form.appointment.patient.id,
        insurancePlanId: this.form.appointment.insurance.plan.id,
        templateId: templateId || this.form.templateId,
        inputs: inputs.map(({ id, model }) => ({ id, ...model })),
      };

      if (Array.isArray(measures) && measures.length > 0) {
        reportData.measures = measures
          .reduce((a, { inputs: measureInputs }) => {
            measureInputs.forEach(({ type, id, value }) => {
              if (type !== 'formula') {
                a.push({ id, value });
              }
            });
            return a;
          }, []);
      }

      if (this.signModal.review && this.signModal.form.professionalReviewId) {
        reportData.professionalReviewId = this.signModal.form.professionalReviewId;
      }

      if (this.signModal.reminder && this.signModal.form.reminderDate) {
        reportData.reminderDate = this.signModal.form.reminderDate;
      }

      const request = !this.form.id
        ? this.$http.post('/medical-reports/', reportData)
        : this.$http.put(`/medical-reports/${this.form.id}`, reportData);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          this.$toast.show('Informações salvas');
          if (sign) {
            this.signModal.show = false;
          }
          this.form.steps = data.steps;
          this.form.stage = data.stage;
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
    async sign() {
      this.clearSignModal();
      this.signModal.show = true;

      if (this.signModal.professionals.length === 0) {
        await this.loadProfessionals();
      }

      const professional = this.signModal.professionals
        .find(({ id }) => id === this.user.id);

      if (professional) {
        this.signModal.form.professionalId = professional.id;
      }
    },
    async resign() {
      if (!this.canReassignAccess) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<h5 class="text-center">Atenção!</h5>'
          + '<div>Deseja realmente liberar este laudo para edição?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.saving = true;
              this.$http
                .post(`/medical-reports/${this.form.id}/resign`)
                .then(() => {
                  this.load(this.form.id);
                })
                .catch(() => {
                  this.$toast.show('Ocorreu um erro. Tente novamente!', { type: 'error' });
                })
                .then(() => {
                  this.saving = false;
                });
              close();
            },
          },
        ],
      });
    },
    imageBlankForm() {
      return {
        file: null,
        preview: null,
        showOptions: false,
        showWebCam: false,
      };
    },
    clearSignModal() {
      this.$v.signModal.form.$reset();
      this.signModal.review = false;
      this.signModal.reminder = false;
      this.signModal.form = this.blankSignModalForm();
    },
    blankSignModalForm() {
      return {
        reminderDate: '',
        professionalId: '',
        professionalReviewId: '',
      };
    },
    blankForm() {
      return {
        id: '',
        stage: '',
        steps: [],
        appointment: {
          id: '',
          date: '',
          schedule: {
            id: '',
            name: '',
          },
          professional: {
            id: '',
            name: '',
          },
          insurance: {
            id: '',
            name: '',
            plan: {
              id: '',
              name: '',
            },
          },
          patient: {
            id: '',
            name: '',
            birthDate: '',
            gender: '',
            imageUrl: '',
          },
          expenses: [],
        },
        templateId: '',
        inputs: [],
        measures: [],
        dicomUrl: '',
        audios: [],
        images: [],
        externalReport: null,
        printLayout: null,
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.medical-report-page {
  $page-header-height: 3.7rem;

  .medical-report-header {
    background-color: $light-color;
    border-bottom: $border-width solid $border-color;
    height: $page-header-height;
    position: sticky;
    top: 0;
    z-index: $zindex-0 + 1;
  }
  .medical-report-content {
    display: flex;
    margin-left: $layout-spacing-lg;
    .mr-content {
      background-color: #fff;
      border-radius: $border-radius;
      flex: 1;
      padding: $layout-spacing-lg;
      overflow-x: auto;
      max-width: 42rem;
      margin: $layout-spacing-lg auto;
      .content-actions {
        margin-bottom: $layout-spacing;
        .btn {
          width: 7rem;
        }
      }
      .document-editor {
        overflow-x: hidden;
        .document-editor-body {
          .ck.ck-content.ck-editor__editable {
            min-height: 40rem;
            //padding: 15px 72px;
            padding: 15px;
          }
        }
      }
    }
    .mr-sidebar {
      // background-color: #eee;
      flex: 0 0 14rem;
      padding: $layout-spacing-lg;
    }
  }
  .bottom-bar {
    background-color: $light-color;
    border-top: $border-width solid $gray-color-light;
    bottom: 0;
    padding: $layout-spacing-lg;
    left: 110px;
    position: absolute;
    right: 0;
    .audio-media {
      width: 100%;
    }
  }
  .form-horizontal {
    .form-group {
      align-items: center;
    }
    .input-group-addon {
      width: 2.2rem;
      font-size: $font-size-xs;
      font-weight: 500;
    }
  }
}
</style>
