<template>
  <div class="mr-sidebar">
    <div>Status: <strong>{{ status }}</strong></div>
    <div
      v-if="professionalName"
      class="d-flex h-centered text-info pt-1 pb-1 tooltip tooltip-left"
      data-tooltip="Profissional"
    >
      <fa-icon :icon="['fal', 'info-circle']"></fa-icon>
      <span class="ml-1">{{ professionalName }}</span>
    </div>
    <div class="mt-2">
      <button
        v-if="!isFinished"
        class="btn btn-block btn-primary btn-lg btn-icon btn-icon-left"
        @click="sign"
        :disabled="!canSign"
      ><fa-icon :icon="['fal', 'user-edit']"></fa-icon> Assinar</button>
      <button
        v-else
        class="btn btn-block btn-info btn-lg btn-icon btn-icon-left"
        @click="resign"
        :disabled="!canReSign"
      ><fa-icon :icon="['fal', 'user-edit']"></fa-icon> Reassinar</button>
    </div>
    <div class="mt-1">
      <div class="btn-group btn-group-block">
        <button
          class="btn btn-gray btn-lg btn-icon btn-icon-left"
          :class="{ loading: printing }"
          :disabled="printing"
          @click="print"
        ><fa-icon :icon="['fal', 'print']"></fa-icon> Imprimir</button>
        <button
          class="btn btn-gray btn-lg btn-icon btn-option"
          @click="printLayout.show = true"
        ><fa-icon :icon="['fal', 'cog']"></fa-icon></button>
      </div>
    </div>

    <div class="mt-1">
      <button class="btn btn-lg btn-info btn-block btn-icon btn-icon-left"
              :class="{ loading: transferReportModal.loading }"
              :disabled="transferReportModal.loading"
              v-if="canEdit"
              @click="openTransferReport">
        <fa-icon :icon="['fal', 'arrow-right']"></fa-icon>Transferir laudo
      </button>
    </div>

    <div class="divider" />

    <div class="mt-2" v-if="canEdit && !isExternal">
      <select class="form-select select-lg" @change="changeMask">
        <option value="">Máscaras</option>
        <optgroup label="Minhas máscaras">
          <option
            v-for="item in myModels"
            :value="item.id"
            :key="item.id"
          >{{ item.name }}</option>
        </optgroup>
        <optgroup label="Outras máscaras" v-if="!isProfessionalOnly">
          <option
            v-for="item in otherModels"
            :value="item.id"
            :key="item.id"
          >{{ item.name }}</option>
        </optgroup>
      </select>
    </div>

    <div class="mt-2">
      <span class="stenci-title">Gravações de aúdio</span>
      <div v-if="!audios || audios.length === 0">
        <div class="label label-primary">Não há gravações de audio</div>
      </div>
      <div class="" v-for="(audio, i) in audios" :key="audio.id">
        <div class="tile label label-secondary text-light p-2 mt-1">
          <div class="tile-icon">
            <fa-icon :icon="['fas', 'play']"></fa-icon>
          </div>
          <div class="tile-content">
            Gravação #{{ i + 1 }} - {{ audio.date | date('datetime') }}
          </div>
        </div>
        <!--            <audio-media-->
        <!--              class="mt-1"-->
        <!--              style="width: 100%"-->
        <!--              v-for="audio in form.audios"-->
        <!--              :src="audio.url"-->
        <!--              :key="audio.id">-->
        <!--            </audio-media>-->
      </div>
    </div>

    <div class="pt-2 mt-2">
      <div
        class="dropdown dropdown-right d-block"
        :class="{ active: viewer.menu }"
      >
        <a
          class="btn btn-lg btn-gray btn-block btn-icon btn-icon-left"
          :class="{ loading: viewer.loading, disabled: !viewer.enabled }"
          @click="toggleViewerMenu"
        ><fa-icon :icon="['fal', 'eye']"></fa-icon> Visualizador DICOM</a>
        <ul class="menu" v-if="viewer.menu" v-click-outside="toggleViewerMenu">
          <li
            v-for="item in viewer.items"
            :key="item.type"
            class="menu-item"
          ><a :href="item.url" :target="item.target">{{ item.label }}</a></li>
        </ul>
      </div>
    </div>

    <div class="pt-2">
      <div class="columns _col-gapless">
        <div class="column col-6">
          <button
            class="btn btn-lg btn-gray btn-block btn-icon btn-icon-left"
            @click="modal.history = true"
          ><fa-icon :icon="['fal', 'history']"></fa-icon> Histórico</button>
        </div>
        <div class="column col-6">
          <button
            class="btn btn-lg btn-gray btn-block btn-icon btn-icon-left"
            @click="modal.attachment = true"
          ><fa-icon :icon="['fal', 'paperclip']"></fa-icon> Anexos</button>
        </div>
      </div>
    </div>

    <div class="pt-2">
      <button
        class="btn btn-lg btn-block btn-icon btn-icon-left"
        :class="images.show ? 'btn-info' : 'btn-gray'"
        @click="images.show = !images.show"
      >
        <fa-icon :icon="['fal', 'image']"></fa-icon>
        <span>Imagens</span>
        <span
          v-if="imagesCount > 0"
          class="ml-1"
        >({{selectedImagesCount}}/{{ imagesCount }})</span>
      </button>
    </div>

    <div class="mt-2 import-external-report">
      <button
        class="btn btn-lg btn-gray btn-block btn-icon btn-icon-left"
        :class="{ loading: external.loading }"
        :disabled="!canEdit"
        v-if="!isExternal"
      >
        <fa-icon :icon="['fal', 'cloud-upload']"></fa-icon> Importar laudo externo
        <input type="file" accept="application/pdf" @change="loadReport" v-if="canEdit">
      </button>
      <button
        class="btn btn-lg btn-error btn-block btn-icon btn-icon-left"
        :class="{ loading: external.removing }"
        :disabled="!canEdit"
        @click="removeExternalReport"
        v-else
      >
        <fa-icon :icon="['fal', 'times-circle']"></fa-icon> Remover laudo externo
      </button>
    </div>

    <!-- Patient Attachments -->
    <dx-modal
      title="Anexos do paciente"
      id="modal-attachment"
      size="md"
      v-if="modal.attachment"
      v-model="modal.attachment"
    >
      <st-entity-file-manager
        :entity-id="patient.id"
        :origin-id="appointment.id"
        origin-type="appointment"
        :readonly="true"
      />
      <template slot="footer">
        <button class="btn" @click="modal.attachment = false">Sair</button>
      </template>
    </dx-modal>

    <!-- Medical Report History -->
    <dx-modal
      title="Laudos do paciente"
      id="modal-history"
      size="md"
      v-if="modal.history"
      v-model="modal.history"
    >
      <history-modal :patient-id="patient.id" />
      <template slot="footer">
        <button class="btn" @click="modal.history = false">Sair</button>
      </template>
    </dx-modal>

    <!-- Image Capture -->
    <dx-modal
      title="Captura de imagem"
      v-model="modal.webcam"
      size="lg"
      id="image-capture"
    >
      <div class="capture-preview">
        <div class="images">
          <div
            v-for="(item, i) in capturedItems"
            class="mb-2 image-item c-zoom-in"
            :style="{backgroundImage: `url(${item.url || item.dataURL})`}"
            :class="{loading: item.status === 'uploading'}"
            @click="images.viewer = item"
            :key="`captured-image-${i}`"
          >
            <span
              v-if="item.id"
              class="image-item-remove c-hand"
              @click.stop="removeImage(item)"
            ><fa-icon :icon="['fal', 'times']"></fa-icon></span>
          </div>
        </div>
        <div class="camera">
          <image-capture
            v-if="modal.webcam"
            :width="352 * 2"
            :height="264 * 2"
            :continuous="true"
            @snapshot="webcamSnapshot"
            ref="webcamSnapshot"
          />
        </div>
      </div>

      <div slot="footer">
        <div class="form-group" v-show="configMode">
          <div class="input-group">
            <span
              class="input-group-addon text-info"
            >Clique no campo a seguir e pressione o pedal ou uma tecla</span>
            <input
              type="text"
              class="form-input text-center"
              @keypress.prevent="setCustomCaptureKeyHandler"
              @mouseup.prevent="setCustomCaptureKeyHandler"
              @contextmenu.prevent
            >
            <span class="input-group-addon text-info text-bold">{{ customCaptureKeyCode }}</span>
            <button
              class="btn input-group-btn btn-icon btn-icon-left btn-info"
              @click="configMode=false"
            ><fa-icon :icon="['fal', 'check']"></fa-icon> Salvar</button>
          </div>
        </div>
        <div class="columns">
          <div class="column text-left">
            <button
              class="btn btn-icon btn-icon-left"
              :class="configMode ? 'btn-info' : 'btn-gray-outline'"
              @click="configMode=!configMode"
            ><fa-icon :icon="['fal', 'cog']"></fa-icon> Pedal</button>
          </div>
          <div class="column">
            <button class="btn btn-gray mr-2" @click="closeCapture">Fechar</button>
            <button
              class="btn btn-primary btn-icon-left"
              @click="takeSnapshot"
              ref="btnTakeSnapshot"
              :disabled="configMode"
            >
              <fa-icon :icon="['fal', 'camera']"></fa-icon> Capturar
            </button>
          </div>
        </div>
      </div>
    </dx-modal>

    <!-- Print Layout -->
    <dx-modal
      title="Layout de impressão"
      v-model="printLayout.show"
      id="modal-print-layout"
    >
      <div class="mb-2">
        <label class="form-switch">
          <input type="checkbox" v-model="printLayout.form.compact">
          <i class="form-icon"></i>
          <strong>Layout compacto</strong> (laudo e imagens na mesma página)
        </label>
      </div>
      <div class="form-group">
        <label for="">Distribuição das imagens</label>
        <select
          class="form-select"
          v-model="printLayout.form.imageColumns"
          :disabled="printLayout.form.compact"
        >
          <option :value="0">Automático</option>
          <option :value="1">1 coluna</option>
          <option :value="2">2 colunas</option>
          <option :value="3">3 colunas</option>
          <option :value="4">4 colunas</option>
        </select>
      </div>

      <print-preview
        :compact="printLayout.form.compact"
        :image-cols="printLayout.form.imageColumns"
        :images-count="selectedImagesCount"
        :max-cols="4"
      ></print-preview>

      <template v-slot:footer>
        <button
          class="btn"
          :class="{ loading: printLayout.saving }"
          :disabled="printLayout.saving"
          @click="savePrintLayout(true)"
        >Salvar e imprimir</button>
        <button
          class="btn btn-primary ml-2"
          :class="{ loading: printLayout.saving }"
          :disabled="printLayout.saving"
          @click="savePrintLayout(false)"
        >Salvar</button>
      </template>
    </dx-modal>

    <!-- Image Viewer -->
    <dx-modal
      title="Visualização de imagem" size="lg"
      v-if="images.viewer"
      :value="!!images.viewer"
      @input="images.viewer = null"
      :clickToClose="true"
    >
      <img
        :src="images.viewer.url"
        class="img-responsive"
        style="margin: 0 auto"
      >
    </dx-modal>

    <!-- Change Professional -->
    <dx-modal
      title="Transferir laudo"
      size="sm"
      v-model="transferReportModal.show"
      v-if="transferReportModal.show"
      id="transfer-report-modal"
    >
      <div class="item-alert">
        <fa-icon :icon="['fal', 'exclamation-triangle']" class="mr-2" />
        <strong>Atenção</strong>
        <div class="mt-1">
          Ao transferir este laudo para outro profissional você não conseguirá mais editá-lo!
        </div>
      </div>
      <div>
        <label class="form-label">Profissional</label>
        <select id="expense-professional" class="form-select"
                v-model="transferReportModal.professionalId">
          <option value="">[Selecione um profissional]</option>
          <option v-for="(item, i) in filteredProfessionals"
                  :value="item.id" :key="i">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div slot="footer">
        <button class="btn btn-icon btn-icon-left btn-primary mr-1"
                :class="{loading: transferReportModal.saving}"
                :disabled="!transferReportModal.professionalId || transferReportModal.saving"
                @click="saveTransferReport">
          <fa-icon :icon="['fal', 'save']"/>Transferir
        </button>
        <button class="btn btn-gray"
                @click="transferReportModal.show = false">Fechar
        </button>
      </div>
    </dx-modal>

    <portal to="report-bottom-bar" v-if="images.show">
      <div class="report-images-bar">
        <div class="actions">
          <button
            class="btn btn-info btn-icon-left btn-block btn-lg"
            @click="openCapture"
            :disabled="!canEdit"
          >
            <fa-icon :icon="['fal', 'camera']"></fa-icon>
            Capturar
          </button>
          <button
            class="btn btn-gray btn-icon-left btn-block btn-lg"
            style="position: relative;"
            :disabled="!canEdit"
          >
            <input type="file" name="image" @change="loadImage"
                   accept="image/png, image/jpeg" multiple="multiple"
                   style="opacity: 0; position: absolute; width: 100%;" ref="uploadFile">
            <fa-icon :icon="['fal', 'upload']"></fa-icon>
            Upload
          </button>
        </div>

        <div style="flex: 1; overflow-x: auto;">
          <dnd-container @drop="onDropImage" orientation="horizontal" class="images">
            <dnd-draggable
              v-for="(item, i) in images.items"
              :key="i"
              :drag-not-allowed="item.status !== 'ready'"
            >
              <div
                class="image-thumbnail c-move"
                :class="{loading: item.status === 'uploading'}"
                :style="{backgroundImage: `url(${item.url || item.dataURL})`}"
              >
                <div
                  class="image-actions"
                  v-if="item.id && item.status !== 'deleting'"
                >
                  <button
                    class="image-action-item"
                    :class="{'image-item-selected': item.selected}"
                    :disabled="!canEdit"
                    @click.stop="setPrint(item)"
                  ><fa-icon
                    :icon="['fal', item.selected ? 'check' : 'circle']"
                  ></fa-icon></button>
                  <button
                    class="image-action-item"
                    @click="images.viewer = item"
                  ><fa-icon :icon="['fal', 'expand']"></fa-icon></button>
                  <span></span>
                  <button
                    class="image-action-item image-item-remove"
                    v-if="canEdit"
                    @click.stop="removeImage(item)"
                  ><fa-icon :icon="['fal', 'times']"></fa-icon></button>
                </div>
              </div>
            </dnd-draggable>
          </dnd-container>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { Container as DndContainer, Draggable as DndDraggable } from 'vue-dndrop';
import localforage from 'localforage';
import ImageCapture from '@/components/image/Capture.vue';
import { applyDrag } from '@/helpers/dragAndDrop';
import { UPDATE_PERMISSION_GROUP } from '@/data/actions/modules/general';
import * as professionalApi from '@/app/professional/api';
import HistoryModal from './History.vue';
import { normalizeImage, normalizeImageUrl, fetchViewerData } from '../helpers';
import PrintPreview from './PrintPreview.vue';

export default {
  props: {
    data: {
      id: {
        type: String,
      },
      dicomUrl: {
        type: String,
      },
      audios: {
        type: Array,
      },
      appointment: {
        type: Object,
      },
      steps: {
        type: Array,
      },
    },
    isFinished: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canReSign: {
      type: Boolean,
      default: false,
    },
    isExternal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImageCapture,
    HistoryModal,
    DndContainer,
    DndDraggable,
    PrintPreview,
  },
  data() {
    return {
      printing: false,
      models: [],
      modal: {
        attachment: false,
        history: false,
        webcam: false,
      },
      transferReportModal: {
        show: false,
        loading: false,
        saving: false,
        professionals: [],
        professionalId: '',
      },
      images: {
        show: false,
        items: this.data.images,
        viewer: null,
      },
      external: {
        loading: false,
        removing: false,
      },
      customCaptureKeyCode: null,
      configMode: false,
      printLayout: {
        show: false,
        saved: false,
        saving: false,
        form: {
          compact: false,
          imageColumns: 0,
        },
      },
      viewer: {
        menu: false,
        enabled: true,
        loading: false,
        items: [],
      },
    };
  },
  mounted() {
    if (this.data && this.data.printLayout) {
      this.printLayout.saved = true;
      this.printLayout.form = Object.assign(this.printLayout.form, this.data.printLayout);
    }
    this.loadModels();
  },
  watch: {
    'printLayout.form.compact': function compactHandler(newVal) {
      if (newVal) {
        this.printLayout.form.imageColumns = 0;
      }
    },
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
    ...mapGetters({
      isProfessionalOnly: 'isProfessionalOnly',
    }),
    canSign() {
      return this.canEdit || this.$can(UPDATE_PERMISSION_GROUP);
    },
    lastStep() {
      return Array.isArray(this.data.steps) && this.data.steps.length > 0
        ? this.data.steps[this.data.steps.length - 1]
        : null;
    },
    professionalName() {
      return this.lastStep && this.lastStep.professional.id !== this.user.id
        ? this.lastStep.professional.name
        : '';
    },
    audios() {
      return Array.isArray(this.data.audios)
        ? this.data.audios
        : [];
    },
    appointment() {
      if (this.data && this.data.appointment) {
        return this.data.appointment;
      }
      return null;
    },
    patient() {
      if (this.appointment && this.appointment.patient) {
        return this.appointment.patient;
      }
      return null;
    },
    status() {
      switch (this.data.stage) {
        case 'finished':
          return 'Assinado';
        case 'report':
          return 'Laudando';
        case 'examined':
          return 'Para laudar';
        case 'review':
          return 'Em revisão';
        default:
          return '--';
      }
    },
    myModels() {
      return this.models.filter(({ professionals }) => (
        professionals.some(({ id }) => id === this.user.id)
      ));
    },
    otherModels() {
      return this.models.filter(({ professionals }) => (
        !professionals.some(({ id }) => id === this.user.id)
      ));
    },
    capturedItems() {
      return this.images.items.filter(item => item.capture).reverse();
    },
    captureKeyCodes() {
      const keys = [
        { type: 'key', value: 13 },
        { type: 'key', value: 32 },
      ];
      if (this.customCaptureKeyCode && !this.configMode) {
        keys.push(this.parseCaptureKey(this.customCaptureKeyCode));
      }
      return keys;
    },
    filteredProfessionals() {
      return this.transferReportModal.professionals.filter(item => item.id !== this.user.id);
    },
    imagesCount() {
      return this.images.items.length;
    },
    selectedImagesCount() {
      return this.images.items.filter(item => item.selected).length;
    },
    captureByMouseEvent() {
      return this.customCaptureKeyCode
        && typeof this.customCaptureKeyCode === 'string'
        && this.customCaptureKeyCode.startsWith('mouse');
    },
  },
  methods: {
    sign() {
      this.$emit('sign');
    },
    resign() {
      this.$emit('resign');
    },
    save() {
      this.$emit('save');
    },
    async print() {
      if (!this.printLayout.saved) {
        this.printLayout.show = true;
        return;
      }

      this.printing = true;
      try {
        await this.$file.print(`/medical-reports/${this.data.id}/print`);
      } catch (e) {
        this.$toast.error(e);
      }
      this.printing = false;
    },
    async openTransferReport() {
      this.transferReportModal.loading = true;
      if (this.transferReportModal.professionals.length === 0) {
        await this.loadProfessionals();
      }
      this.transferReportModal.show = true;
      this.transferReportModal.professionalId = '';
      this.transferReportModal.loading = false;
    },
    saveTransferReport() {
      this.transferReportModal.saving = true;
      const data = {
        transferProfessionalId: this.transferReportModal.professionalId,
      };
      this.$http.put(`/medical-reports/${this.data.id}`, data)
        .then(() => {
          this.$router.back();
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.transferReportModal.saving = false;
        });
    },
    async savePrintLayout(print = false) {
      this.printLayout.saving = true;

      const postData = {
        printLayout: {
          compact: this.printLayout.form.compact,
          imageColumns: this.printLayout.form.imageColumns,
        },
      };

      try {
        await this.$http.put(`/medical-reports/${this.data.id}`, postData);
        this.printLayout.show = false;
        this.printLayout.saved = true;
        if (print) {
          await this.print();
        }
      } catch (e) {
        this.$toast.error(e);
      }

      this.printLayout.saving = false;
    },
    loadProfessionals() {
      return professionalApi.allActive()
        .then((data) => {
          this.transferReportModal.professionals = data.items;
        })
        .catch(this.$toast.error);
    },
    changeMask(e) {
      if (e.target.value) {
        const mask = this.models.find(({ id }) => id === e.target.value);
        this.$emit('change-mask', mask);
      }
    },
    loadModels() {
      const params = {
        limit: 0,
      };

      if (this.isProfessionalOnly) {
        params.professionalId = this.user.id;
      }

      return this.$http.get('/report-templates', { params })
        .then(({ data }) => {
          this.models = data.items;
        })
        .catch(this.$toast.error);
    },
    getModel() {
      if (!this.modelId) {
        return;
      }
      this.$http.get(`/report-templates/${this.modelId}`)
        .then(({ data }) => {
          this.form.report.inputs[0].model.value = data.data;
        })
        .catch(this.$toast.error);
    },
    async preloadImage(src) {
      const preload = new Image();
      preload.src = src;
      return new Promise((resolve, reject) => {
        preload.onload = resolve;
        preload.onerror = reject;
      });
    },
    async addImage(imageData) {
      const item = normalizeImage({
        ...imageData,
        status: 'uploading',
      });

      this.images.items.push(item);

      const formData = new FormData();
      formData.append('file', item.file);

      try {
        const { data } = await this.$http
          .post(`/medical-reports/${this.data.id}/images`, formData);

        Object.assign(item, data, { url: null });
        data.url = normalizeImageUrl(data.url);

        item.status = 'processing';
        await this.preloadImage(data.url).catch(() => {});

        item.url = data.url;
        item.dataURL = null;
        item.status = 'ready';
      } catch (e) {
        this.$toast.error(e);
        item.status = 'error';
      }

      return item;
    },
    webcamSnapshot(image) {
      if (image !== null) {
        this.addImage({ ...image, capture: true });
      }
    },
    onDropImage(dropResult) {
      const result = applyDrag(this.images.items, dropResult);

      if (result.changed) {
        this.images.items = result.items;
        this.$http
          .put(`/medical-reports/${this.data.id}/images/reorder`, (
            this.images.items.map(({ id }) => id)
          ))
          .catch(this.$toast.error);
      }
    },
    setPrint(item) {
      if (!this.canEdit) {
        return Promise.resolve();
      }

      const data = { selected: !item.selected };

      return this.$http
        .put(`/medical-reports/${this.data.id}/images/${item.id}`, data)
        .then(() => {
          item.selected = data.selected;
        })
        .catch(this.$toast.error);
    },
    async removeImage(item) {
      item.status = 'deleting';
      try {
        await this.$http
          .delete(`/medical-reports/${this.data.id}/images/${item.id}`);
        this.images.items.splice(this.images.items.indexOf(item), 1);
      } catch (e) {
        this.$toast.error(e);
      }
    },
    async openCapture() {
      this.modal.webcam = true;
      await this.loadCustomCaptureKey();
      this.addCaptureListeners();
    },
    closeCapture() {
      this.modal.webcam = false;
      this.removeCaptureListeners();
    },
    addCaptureListeners() {
      document.activeElement.blur();
      if (this.captureByMouseEvent) {
        document.addEventListener('contextmenu', this.captureListener);
        document.addEventListener('mouseup', this.captureListener);
      }
      document.addEventListener('keypress', this.captureListener);
    },
    removeCaptureListeners() {
      if (this.captureByMouseEvent) {
        document.removeEventListener('contextmenu', this.captureListener);
        document.removeEventListener('mouseup', this.captureListener);
      }
      document.removeEventListener('keypress', this.captureListener);
    },
    captureListener(event) {
      event.preventDefault();

      if (event.type === 'contextmenu') {
        return;
      }

      const type = this.parseEventType(event);
      const value = event.which || event.keyCode;

      const canTakeSnapshot = this.captureKeyCodes.some(item => (
        item.type === type && item.value === value
      ));

      if (canTakeSnapshot) {
        this.takeSnapshot();
      }
    },
    async loadCustomCaptureKey() {
      if (this.customCaptureKeyCode === null) {
        let value = await localforage.getItem('medicalReportCustomCaptureKeyCode');
        if (value) {
          if (typeof value === 'number' || !value.includes(':')) {
            value = `key:${value}`;
            this.setCustomCaptureKey(value).catch(this.$toast.error);
          }
          this.customCaptureKeyCode = value;
        }
      }
    },
    setCustomCaptureKey(keyCode) {
      keyCode = keyCode || null;
      this.customCaptureKeyCode = keyCode;
      return localforage.setItem('medicalReportCustomCaptureKeyCode', keyCode);
    },
    setCustomCaptureKeyHandler(event) {
      const type = this.parseEventType(event);
      const which = event.which || event.keyCode;

      if (type === 'mouse' && which === 1) {
        return;
      }

      this.setCustomCaptureKey([type, which].join(':'));
    },
    takeSnapshot() {
      this.$refs.webcamSnapshot.snapshot();
    },
    loadImage(e) {
      e.preventDefault();
      if (e.target.files.length) {
        Array.from(e.target.files).forEach((file) => {
          const item = {
            file,
            dataURL: null,
            reader: new FileReader(),
          };

          item.reader.onload = (re) => {
            item.dataURL = re.target.result;
            item.reader = null;
            this.addImage(item);
          };

          item.reader.readAsDataURL(item.file);
        });

        this.$refs.uploadFile.value = '';
      }
    },
    async loadReport(e) {
      e.preventDefault();
      if (e.target.files.length) {
        this.external.loading = true;
        const [file] = e.target.files;

        const formData = new FormData();
        formData.append('file', file);

        try {
          const { data } = await this.$http
            .post(`/medical-reports/${this.data.id}/external`, formData);
          this.$emit('external-report', data);
        } catch (error) {
          this.$toast.show('Erro ao importar o laudo', { type: 'error' });
        } finally {
          this.external.loading = false;
        }
      }
    },
    async removeExternalReport() {
      this.external.removing = true;
      try {
        await this.$http.delete(`/medical-reports/${this.data.id}/external`);
        this.$emit('external-report', null);
      } catch (error) {
        this.$toast.show('Erro ao remover o laudo', { type: 'error' });
      } finally {
        this.external.removing = false;
      }
    },
    parseEventType(event) {
      return event.type.startsWith('key') ? 'key' : 'mouse';
    },
    parseCaptureKey(value) {
      let type = 'key';

      if (typeof value === 'string' && value.includes(':')) {
        [type, value] = value.split(':');
      }

      return { type, value: Number(value) };
    },
    async toggleViewerMenu() {
      if (!this.viewer.enabled) return;
      await this.loadViewerOptions();
      this.viewer.menu = this.viewer.items.length > 0 ? !this.viewer.menu : false;
    },
    async loadViewerOptions() {
      if (this.viewer.loading || this.viewer.items.length > 0) return;
      this.viewer.loading = true;
      try {
        const { items } = await fetchViewerData(this.data.id, (state) => {
          this.viewer.enabled = state !== 'locked';
        });
        this.viewer.items = items;
      } catch (e) {
        this.$toast.error(e);
      }
      this.viewer.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
@import 'src/assets/scss/colors';

$image-bar-size: 5rem;

.mr-sidebar {
  .btn-group.btn-group-block {
    .btn-option {
      flex: 0 0 $unit-10;
    }
  }
  .import-external-report {
    .btn {
      position: relative;
      input {
        bottom: 0;
        display: block;
        left: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: $zindex-0;
      }
    }
  }
}

.report-images-bar {
  display: flex;
  .actions {
    display: flex;
    flex: 0 0 6rem;
    flex-direction: column;
    height: $image-bar-size;
    justify-content: space-between;
    .btn {
      height: 2.2rem;
    }
  }
  .images {
    display: flex;
    height: $image-bar-size;
    .image-thumbnail {
      background-color: $gray-color-light;
      background-size: cover;
      background-position: center;
      border-radius: $border-radius;
      display: block;
      flex: 0 0 $image-bar-size + 2rem;
      width: $image-bar-size + 2rem;
      height: $image-bar-size;
      margin-left: $layout-spacing-lg;
      position: relative;
      .image-actions {
        display: flex;
        left: $layout-spacing-sm;
        position: absolute;
        right: $layout-spacing-sm;
        top: $layout-spacing-sm;
        span {
          flex: 1;
        }
      }
      .image-action-item {
        border: 0;
        border-radius: 50%;
        background-color: $body-bg;
        color: $body-font-color;
        cursor: pointer;
        font-size: $font-size;
        height: auto;
        margin-left: $layout-spacing-sm;
        padding: $layout-spacing-sm;
        width: auto;
        svg {
          display: block;
          height: $layout-spacing-lg;
          width: $layout-spacing-lg;
        }
        &:focus {
          outline: none;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
      .image-item-selected {
        background: rgba($info-color, .9);
        color: $light-color;
      }
      .image-item-remove {
        background: rgba($error-color, .9);
        color: $light-color;
      }
    }
  }
}

#transfer-report-modal {
  .item-alert {
    background-color: $red-color-100;
    border: $border-width solid $red-color-300;
    border-radius: $border-radius;
    font-weight: 500;
    margin: $layout-spacing 0;
    padding: $layout-spacing;
    svg {
      color: $red-color-600;
    }
  }
}

#image-capture {
  .modal-container {
    width: 50rem;
  }
  .capture-preview {
    display: flex;
    height: 528px;
    .images {
      width: 12rem;
      overflow-y: auto;
      .image-item {
        background-size: cover;
        background-position: center;
        border-radius: $border-radius;
        border: 1px solid $primary-color  ;
        height: 7rem;
        position: relative;
        width: 100%;
        .image-item-remove {
          position: absolute;
          background: $light-color;
          border-radius: 50%;
          right: $layout-spacing;
          top: $layout-spacing;
          width: 1rem;
          height: 1rem;
          text-align: center;
        }
      }
    }
    .camera {
      margin-left: $layout-spacing;
    }
  }
}

#modal-print-layout {
  .modal-container {
    width: 20.8rem + ($layout-spacing-lg * 4);
  }
  .print-layout-preview {
    margin-top: $layout-spacing-lg;
  }
}
</style>
